<template>
  <div style="overflow-y: auto; max-height: 70vh">
    <p
      v-if="currentOpenItem.user"
      class="mb-0 pl-3"
    >{{ currentOpenItem.user.username }} <br>{{ currentOpenItem.user.phone }}</p>
    <p
      v-if="currentOpenItem.user"
      class="mt-0 pl-3"
    > {{ currentOpenItem.user.email }}</p>
    <div
      class="mt-2 ma-2 container-attr"
      v-for="(item, index) in currentOpenItem.logItems"
      :key="item.id"
    >
      <v-list-item-title class="pl-0 pb-2 pt-1">Атрибут: {{ item.attr_name }}</v-list-item-title>
      <p class="ma-0"><span class="orange--text">Старое значение:</span> {{ item.old_value }}</p>
      <p class="ma-0"><span class="green--text">Новое значение:</span> {{ item.new_value }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailChanges',
  props: {
    currentOpenItem: Object
  }
}
</script>

<style scoped>
  .container-attr {
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    padding: 3px;
    padding-left: 5px;
  }
</style>