<template>
  <div class="content-block">
    <BasePreloader v-if="isLoading"/>
    <div class="d-flex justify-end close mr-2">
      <v-icon
        color="primary"
        v-if="!!Object.keys(currentOpenItem).length"
        @click="currentOpenItem = {}"
      >mdi-close
      </v-icon>
    </div>

    <div v-if="!Object.keys(currentOpenItem).length">
      <ListChanges
        v-for="(item, index) in items.models"
        :key="item.id"
        @click="handleClickItem(item)"
        :item="item"
        :is-line-bottom="index !== items.models.length - 1"
      />
      <v-pagination
        v-if="items.count > 25"
        :total-visible="5"
        class="mt-2"
        :length="countPages"
        v-model:value="localQuery.options.page"
      />
      <BaseAlert
        v-if="!items.models || items.models.length === 0 && !isLoading"
        :text="$t('message.noChanges')"
        type="info"
        class="ml-2"
      />
    </div>

    <DetailChanges
      v-if="!!Object.keys(currentOpenItem).length"
      :current-open-item="currentOpenItem"
    />
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import BasePreloader from '@/components/base/UI/BasePreloader'
import BaseAlert from '@/components/base/UI/BaseAlert'
import DetailChanges
  from './DetailChanges'
import ListChanges from '@/components/views/mobile/map/detail/common/historyLog/ListChanges'
import historyLog from '@/components/mixins/commonForLayouts/historyLog'

export default {
  name: 'HistoryLog',
  components: {
    ListChanges,
    DetailChanges,
    BasePreloader,
    BaseAlert
  },
  mixins: [formatters, historyLog],
  props: {
    modelId: Number
  },
  computed: {
    countPages () {
      return Math.ceil(this.items.count / 25)
    }
  },
  methods: {
    handleClickItem (item) {
      this.$set(this, 'currentOpenItem', item)
    }
  }
}
</script>

<style scoped>
  .content-block {
    height: calc(100vh - 240px) !important;
    overflow: hidden;
    overflow-y: visible;
  }
  .log-table /deep/ .v-data-table__wrapper { /* высота контента таблицы*/
    max-height: calc(100vh - 198px) !important;
  }
  .close {
    position: absolute;
    right: 0;
  }
</style>