<template>
  <div class="pa-2 change-item pb-0" @click="$emit('click')">
    <b>{{ timestampToDatetime(item.created_at) }}</b>
    <b :class="typesChanges[item.action].color + '--text d-block'">{{ typesChanges[item.action].title }}</b>
    <div class="mt-3">
      <p class="ma-0">{{ item.user.organization.name }}</p>
      <p class="ma-0">{{ item.user.username }}</p>
      <p class="ma-0">{{ item.user.phone }}</p>
      <p class="ma-0">{{ item.user.email }}</p>
    </div>
    <v-divider
      class="mt-4 pb-0"
      v-if="isLineBottom"
      color="secondary"
    ></v-divider>
  </div>
</template>

<script>
import formatters from '@/components/mixins/formatters'

export default {
  name: 'ListChanges',
  mixins: [formatters],
  props: {
    item: Object,
    isLineBottom: Boolean
  },
  computed: {
    typesChanges () {
      this.isLoading = true
      const list = this.$store.getters['serviceData/get']('log.action')
      const types = {}
      list?.map((item) => {
        types[item.value] = {
          title: item.label,
          color: (item.value === 1 || item.value === 4) ? 'green' : (item.value === 3 || item.value === 5) ? 'red' : 'orange'
        }
      })
      this.isLoading = false
      return types
    }
  }
}
</script>

<style scoped>
  .change-item:hover {
    background: #e6e8ea;
    max-height: 75vh;
  }
</style>